.bg {
    background: url('../../images/api_home.jpg') center center;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
 height: 100vh;
    }

.footerImage {
    
    background: url('../../images/api_footer.png') center center;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}
 @media (min-width: 768px)
 {
	 .navbar {
		width: 80vw;
		left: 14vw;
    }
    
    .Connect-Home--intro-1{
            padding-top: calc(100vh/4) !important;
    }
}