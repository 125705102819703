
.customcard {
    float: left;
    max-width: 360;
    height: 380;
 } 
 @media only screen and (max-width: 768px) {
    .customcard {
       width: 100%;
       height: 100%;
    }
  } 
  @media only screen and (min-width: 768px) {
    .customcard {
       width: 280px;
       padding: 0px 10px 10px 0px;
       height: 380px;
    }
  }
