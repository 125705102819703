
  .card {
    font-size: 12px;
    background: '#F0F2F1';
    border-bottom: 2px solid rgb(187, 176, 176);
  }
  .card > .header {
    width: 100%;
    border-bottom: 2px solid rgb(187, 176, 176);
    font-size: 14px;
    background: '#D0142C';
    text-align: center;
  }
  .content {
    background-color: '#D0142C';
  }